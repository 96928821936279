import * as React from 'react';
import Seo from '../components/SEO';
import Banner from '../components/Banner';

// markup
const IndexPage = () => {
  return (
    <>
      <Seo title='Home' />
      <Banner />
    </>
  );
};

export default IndexPage;
