import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { breakpoints } from './Media';
import Email from '../assets/icons/email.svg';
import Codepen from '../assets/icons/codepen.svg';
import LinkedIn from '../assets/icons/linkedin.svg';

const BannerStyles = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 2rem;
  align-items: center;
  padding: 3rem 0;

  .banner-image {
    border-radius: 50%;
    overflow: hidden;
  }

  .social-links {
    list-style: none;
    padding: 0;
    display: flex;
    margin-top: 2rem;

    li + li {
      margin-left: 20px;
    }
  }

  @media (min-width: ${breakpoints.tabletMin}) {
    grid-template-columns: repeat(2, 1fr);

    .banner-image {
      max-width: 400px;
      justify-self: end;
    }
  }
`;

export default function Banner() {
  return (
    <BannerStyles>
      <div>
        <h1>Hi, I'm Renee!</h1>
        <h2>
          I make <span className='primary'>delightful</span> and{' '}
          <span className='primary'>accessible</span> things for the web
        </h2>
        <p className='subheading'>
          I'm a front-end developer and UX/UI designer. I came to tech from
          non-traditional background and want to make tech and the web more
          friendly for everyone.
        </p>
        <ul className='social-links'>
          <li>
            <a
              href='mailto:hello@reneequinn.dev'
              title='Email me'
              target='_blank'
              rel='noopener noreferrer nofollow'
              className='icon-link'
            >
              <Email />
            </a>
          </li>
          <li>
            <a
              href='https://codepen.io/reneequinn'
              title='Check out my Codepen'
              target='_blank'
              rel='noopener noreferrer'
              className='icon-link'
            >
              <Codepen />
            </a>
          </li>
          <li>
            <a
              href='https://www.linkedin.com/in/renee-quinn/'
              title='Connect on LinkedIn'
              target='_blank'
              rel='noopener noreferrer'
              className='icon-link'
            >
              <LinkedIn />
            </a>
          </li>
        </ul>
      </div>
      <StaticImage
        src='../assets/images/profile-pic.jpg'
        alt='Renee Quinn'
        placeholder='tracedSVG'
        width='500'
        height='500'
        className='banner-image'
      />
    </BannerStyles>
  );
}
